import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { formatDate } from '@/utils';
import { getPathByType } from '@/utils/project';
import { FORMAT_DATE } from '@/config/const'
import { calcGroupPercent } from '@/pages/CreateProject/Blocks/Gantt'
import { round } from 'lodash';
import { getDelayOfDay } from '@/pages/Dashboard2/DashboardProjectStatus/WorkList/utils'

const GroupHeaderItem = ({ group, isOpen, setIsOpen, projectType }) => {
  const { cntDelay, cnt } = group.children
    .filter(work => !['CANCEL', 'SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(work.status.code))
    .reduce(({ cnt, cntDelay }, work) => {
      return {
        cntDelay: cntDelay + (getDelayOfDay(work) === null ? 0 : 1),
        cnt: cnt + 1
      }
    }, { cntDelay: 0, cnt: 0 });
  const { projectVersionId } = group;
  const linkWork = `/${getPathByType(projectType)}/${projectVersionId}/work`

  return (
    <div className="row dashboard-work-header__container" onClick={() => setIsOpen(!isOpen)}>
      <div className="col-lg-5 col-xl-4 flex-center-vertical dashboard-work-header__name">
        <Link target="_blank" to={linkWork} onClick={(e) => e.stopPropagation()}>
          {group.name}
        </Link>
         <div className={cx("arrow-list-item work-group", { 'open': isOpen })}></div>
      </div>
      <div className="col-lg-2 col-xl-1 nowrap">
        <div className={cx('color-indicator mini work-indicator', group.status.code)}></div>
        <div className="color-indicator-text">{group.status.name}</div>
      </div>
      <div className="col-lg-1 col-xl-2">
        Начало <span className="nowrap">{formatDate(group.dateStart, FORMAT_DATE)}</span>
      </div>
      <div className="col-lg-1 col-xl-2">
        <span className="nowrap">Окончание</span> <span className="nowrap">{formatDate(group.dateEnd, FORMAT_DATE)}</span>
      </div>
      <div className="col-lg-3 col-xl-3">
        <span className="nowrap">{ cntDelay } из { cnt } просроченных,</span> <span className="nowrap">{round(calcGroupPercent(group.children, group))}% завершения по группе</span>
      </div>
    </div>
  )
}

export default GroupHeaderItem;
