import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import Status from '@/components/Status';
import { useAppSelector } from '@/store';
import { useExtImport } from '@/utils/hooks';
import ExtendableWrapper from '@/components/ExtendableWrapper';

const HeaderLinks = ({hideExternalLinks = false}) => {
  const extModule = useExtImport<IExtendableProps>('components/ProjectHeader/HeaderLinksExt');

  const [projectData] = useAppSelector(state => [
    state.NewProject.newProjectData,
  ]);

  return (
    <ExtendableWrapper {...extModule} props={{hideExternalLinks: hideExternalLinks}}>
      <Status
        data={{
          status: projectData.statusId,
          isClosed: projectData.isClosed,
          projectId: projectData.projectId,
          projectVersionId: projectData.id,
        }}
        projectData={projectData}
      />
    </ExtendableWrapper>
  );
}

export default HeaderLinks;