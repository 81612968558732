import { cloneDeep, compact, isArray, isEmpty, keyBy } from 'lodash';
import moment from 'moment';
import { XYCoord } from 'react-dnd';
import {
  CalendarType,
  CalendarTypeData,
  ItemType,
  ItemTypes,
  LinkTypesData,
  SAUSAGE_HEIGHT,
} from '@/components/Gantt/const';
import { checkGroup, checkMilestone } from '@/pages/CreateProject/Blocks/utils';
import { getDictCodeById, getDictObj, isEmptyValues, parseDate } from '@/utils';
import { WORK_STATUS_SUCCESS, WorkStatus } from '@/config/const';
import { WeekendUtilType } from '@/components/Gantt/util/dateUtil';
import { updateWorkGanttColorClasses } from '@/pages/CreateProject/Blocks/GanttWithLoad';
import { ShowModal } from '@/utils/hooks';
import {
  checkChildrenNotBeforeParent,
  checkNotBeforeParent,
  fixByLinks,
  showMoveChildrenModal
} from '@/components/Gantt/util/linkUtil';

export const groupWorks = (works: Work[]): GanttTableItem[] => {
  const workClone = cloneDeep(works);
  const workLinks = {};
  workClone.forEach(work => {
    workLinks[work.id] = work;
  });
  const otherProjectWork = [];

  workClone.forEach(work => {
    if (work.workGroupId) {
      if (!workLinks[work.workGroupId]) {
        // ситуация когда работа из другого проекта, и она находится в группе
        otherProjectWork.push(work.id);
      } else {
        workLinks[work.workGroupId].subRows ||= [];
        workLinks[work.workGroupId].subRows.push(work);
      }
    }
  });

  return workClone
    .filter(work => (checkGroup(work) && !work.workGroupId) || !work.workGroupId || otherProjectWork.includes(work.id));
};

export const getGroupIds = (works: Work[]): Record<number, boolean> => {
  return works.reduce((acc, work) => {
    if (checkGroup(work)) {
      acc[work.id] = true;
    }

    return acc;
  }, {});
};

export const getLinkKey = (item: GanttTableLink) => [item.fromId, item.toId, item.typeId].join('_');

export const getDateEndForDuration = (item: Work, workStatusDict: DictItem) => {
  const dateEndFact = parseDate(item.dateEndFact);
  const dateStart = parseDate(item.dateStart);
  const isSuccess = WORK_STATUS_SUCCESS.includes(WorkStatus[getDictCodeById(workStatusDict, item.statusId)]);

  return isSuccess && dateEndFact && item.dateStart && dateEndFact.isSameOrAfter(dateStart)
    ? item.dateEndFact
    : item.dateEnd;
}

export const findByFuncAndUpdate = (
  arr: GanttTableItem[] = [],
  searchFunc: (item: GanttTableItem) => boolean,
  func: (t: GanttTableItem, groupId: number) => GanttTableItem,
  workStatusDict: DictItem,
  weekendUtil: WeekendUtilType,
) => {
  const findAndUpdateLocal = (arr: GanttTableItem[], groupId: number = undefined) => {
    if (!arr.length) {
      return [];
    }

    let item = { ...arr[0] };

    if (searchFunc(item)) {
      const dateStartPrev = parseDate(item.dateStart);
      const dateEndPrev = parseDate(getDateEndForDuration(item, workStatusDict));

      item = { ...func(item, groupId) };

      item.status = getDictObj(workStatusDict, item.statusId);
      updateWorkGanttColorClasses(item);

      const dateStart = parseDate(item.dateStart);
      const dateEnd = parseDate(getDateEndForDuration(item, workStatusDict));
      if (!dateStart || !dateStart.isSame(dateStartPrev)) {
        item.dateStartPrev = dateStartPrev;
      }
      if (!dateEnd || !dateEnd.isSame(dateEndPrev)) {
        item.dateEndPrev = dateEndPrev;
      }
    }

    if (item.subRows) {
      item.subRows = findAndUpdateLocal(item.subRows, item.id);
    }

    return [item, ...findAndUpdateLocal(arr.slice(1), groupId)];
  };

  return findAndUpdateLocal(arr);
};

export const findAndUpdate = (
  arr: GanttTableItem[],
  id: number | number[],
  func: (t: GanttTableItem) => GanttTableItem,
  workStatusDict: DictItem,
  weekendUtil: WeekendUtilType,
) => {
  const ids = isArray(id) ? id : [id];
  const isInId = (item) => {
    return ids.includes(item.id);
  };

  return findByFuncAndUpdate(arr, isInId, func, workStatusDict, weekendUtil);
};

export const searchIndex = (arr: GanttTableItem[], indexSearch: number, expanded) => {
  let deepIndex = 0;
  const searchIndexLocal = (arr: GanttTableItem[]) => {
    if (!arr.length) {
      return;
    }

    const item = { ...arr[0] };
    if (deepIndex === indexSearch) {
      return item;
    }

    deepIndex++;
    if (item.subRows && expanded[item.id]) {
      const result = searchIndexLocal(item.subRows);
      if (result) {
        return result;
      }
    }

    return searchIndexLocal(arr.slice(1));
  };

  return searchIndexLocal(arr);
};

export const removeById = (arr: GanttTableItem[], idRemove: number) => {
  const removeByIdLocal = (arr: GanttTableItem[]) => {
    if (!arr.length) {
      return [];
    }

    const item = { ...arr[0] };
    if (item.id === idRemove) {
      return removeByIdLocal(arr.slice(1));
    }

    if (item.subRows) {
      item.subRows = removeByIdLocal(item.subRows);
    }

    return [item, ...removeByIdLocal(arr.slice(1))];
  };

  return removeByIdLocal(arr);
};

export const searchById = (arr: GanttTableItem[], id: number): GanttTableItem => {
  const searchByIdLocal = (arr: GanttTableItem[]) => {
    if (!arr.length) {
      return null;
    }

    const item = arr[0];
    if (item.id === id) {
      return item;
    }

    if (item.subRows) {
      const result = searchByIdLocal(item.subRows);
      if (result) {
        return result;
      }
    }

    return searchByIdLocal(arr.slice(1));
  };

  return searchByIdLocal(arr);
};

export const insertBeforeId = (
  data: GanttTableItem[],
  idInsert: number,
  isAfter: boolean,
  insertItem: GanttTableItem,
  isOpen: boolean,
  forceInsertGroup: boolean = false,
) => {
  const insertBeforeIndexLocal = (arr: GanttTableItem[]) => {
    if (!arr.length) {
      return [];
    }

    const item = { ...arr[0] };
    const [, ...tail] = arr;

    if (item.subRows) {
      item.subRows = insertBeforeIndexLocal(item.subRows);
    }

    if (item.id === idInsert) {
      if (!isAfter) {
        const isSubrowInsert = !!item.subRows && isOpen && item.subRows.length > 0;
        if (isSubrowInsert || forceInsertGroup) {
          item.subRows ||= [];
          item.subRows.unshift(insertItem);
          return [item, ...insertBeforeIndexLocal(tail)];
        }

        return [item, insertItem, ...insertBeforeIndexLocal(tail)];
      }

      return [insertItem, item, ...insertBeforeIndexLocal(tail)];
    }

    return [item, ...insertBeforeIndexLocal(tail)];
  };

  const result = insertBeforeIndexLocal(data);
  return result;
};

export const updateWorkDataWithChecks = (
  works: GanttTableItem[],
  links: GanttTableLink[],
  workStatusDict: DictItem,
  weekendUtil: WeekendUtilType,
  showModal: ShowModal,
  currentWorkId: number,
  workUpdateFunc: (oldItem: GanttTableItem) => GanttTableItem,
  onSave: (isUpdateChildren: boolean, dropLinkChildrenIds?: number[]) => void,
) => {
  //Обновим локально, чтобы провести проверки
  let newWorks = findAndUpdate(works, currentWorkId, workUpdateFunc, workStatusDict, weekendUtil);
  const updatedItem = keyBy(flatten(newWorks), 'id')[currentWorkId];
  const { fixedResult, isChildrenUpdated } = fixByLinks(currentWorkId, newWorks, links, weekendUtil, workStatusDict);

  try {
    //Проверим не стала ли работа раньше родителя
    checkNotBeforeParent(updatedItem, newWorks, links, weekendUtil, workStatusDict);
  } catch (e: any) {
    showModal(e[0].message, true);
  }

  //Если есть дочерние которые надо подвинуть, спросим
  if (isChildrenUpdated) {
    const errors = [];
    //Сначала проверим не стали ли последующие раньше текущей
    errors.push(...checkChildrenNotBeforeParent(updatedItem, newWorks, links, weekendUtil, workStatusDict));
    //Если все ок, то подвинем и проверим все работы, не нарушилось ли там чего
    if (isEmpty(errors)) {
      errors.push(...checkChildrenNotBeforeParent(updatedItem, fixedResult, links, weekendUtil, workStatusDict, true));
    }
    showMoveChildrenModal(errors, showModal, onSave);
  } else {
    return onSave(false);
  }
}

export const isChild = (items: GanttTableItem[] = [], id: number) => {
  return flatten(items).map(item => item.id).includes(id);
};

export const flatten = (arr: GanttTableItem[] = [], expanded?: GanttTableExpanded): GanttTableItem[] => {
  if (!arr?.length) {
    return [];
  }

  const [item, ...tail] = arr;
  const result = [item];

  if (expanded === undefined || expanded[item.id]) {
    result.push(...flatten(item.subRows, expanded));
  }

  return [...result, ...flatten(tail, expanded)];
};

export const getDateRangeMilestoneSimple = (data: Milestone[]) => {
  const dates = [];

  data.forEach(item => {
    const datePlan = parseDate(item.datePlan);
    const dateFact = parseDate(item.dateFact);

    if (datePlan) {
      dates.push(datePlan);
    }

    if (dateFact) {
      dates.push(dateFact);
    }
  });

  const min = !isEmpty(dates) ? moment.min(dates) : undefined;
  const max = !isEmpty(dates) ? moment.max(dates) : undefined;

  if (isEmptyValues(min) || isEmptyValues(max)) {
    return undefined;
  }

  return {
    min,
    max,
  };
};

export const getDateRangeMilestone = (data: Milestone[], calendarType: CalendarType = CalendarType.DAY): DateRangeType => {
  const dateRange = getDateRangeMilestoneSimple(data);

  if (isEmptyValues(dateRange)) {
    return undefined;
  }

  const calendarTypeData = CalendarTypeData[calendarType];

  return {
    min: dateRange.min.startOf(calendarTypeData.momentType).subtract(1, calendarTypeData.momentType),
    max: dateRange.max.endOf(calendarTypeData.momentType).add(1, calendarTypeData.momentType)
  };
};

export const getDateRangeSimple = (data: GanttTableItem[], workStatusDict: DictItem) => {
  const datesPlan = [];
  const datesFact = [];
  const datesForDuration = [];

  data.forEach(item => {
    const dateStart = parseDate(item.dateStart);
    const dateEnd = parseDate(item.dateEnd);
    const dateEndFact = parseDate(item.dateEndFact);
    const dateEndForDuration = parseDate(getDateEndForDuration(item, workStatusDict));

    if (dateStart) {
      datesPlan.push(dateStart);
    }

    if (dateEnd) {
      datesPlan.push(dateEnd);
    }

    if (dateEndFact) {
      datesFact.push(dateEndFact);
    }

    if (dateEndForDuration) {
      datesForDuration.push(dateEndForDuration);
    }
  });

  const min = !isEmpty(datesPlan) ? moment.min(datesPlan) : undefined;
  const maxPlan = !isEmpty(datesPlan) ? moment.max(datesPlan) : undefined;
  const maxFact = !isEmpty(datesFact) ? moment.max(datesFact) : undefined;
  const maxForDuration = !isEmpty(datesForDuration) ? moment.max(datesForDuration) : undefined;

  if (isEmptyValues(min) || isEmptyValues(maxPlan)) {
    return undefined;
  }

  return {
    min,
    max: maxForDuration,
    maxPlan,
    maxFact,
  };
};

export const getDateRange = (data: GanttTableItem[], calendarType: CalendarType = CalendarType.DAY, workStatusDict: DictItem): DateRangeType => {
  const dateRange = getDateRangeSimple(data, workStatusDict);

  if (isEmptyValues(dateRange)) {
    return undefined;
  }

  const calendarTypeData = CalendarTypeData[calendarType];

  return {
    min: dateRange.min.startOf(calendarTypeData.momentType).subtract(1, calendarTypeData.momentType),
    max: dateRange.max.endOf(calendarTypeData.momentType).add(1, calendarTypeData.momentType)
  };
};

export const getDuration = (
  start: moment.Moment,
  end: moment.Moment,
  type: moment.unitOfTime.DurationConstructor = 'days'
) => {
  if (!start || !end) {
    return null;
  }

  return end.diff(start, type) + 1;
};

export const getSausageWidthOffset = (start: moment.Moment, end: moment.Moment, calendarType: CalendarType, isSingleDay: boolean = false) => {
  const width = CalendarTypeData[calendarType].width;
  return getDuration(start, end) * width + (isSingleDay ? width / 2 : 0);
};

export const getSausageWidth = (start: moment.Moment, end: moment.Moment, calendarType: CalendarType) => {
  return getDuration(start, end) * CalendarTypeData[calendarType].width;
};

export const calcOffset = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  type: ItemType,
  calendarType: CalendarType,
  sausageWidth?: number
): XYCoord => {
  const width = CalendarTypeData[calendarType].width;

  if (type === ItemTypes.RESIZE_LEFT) {
    if (sausageWidth - (currentOffset.x - initialOffset.x) < width) {
      return {
        x: initialOffset.x + (sausageWidth - width),
        y: initialOffset.y
      };
    }

    return {
      x: initialOffset.x + (currentOffset.x - initialOffset.x),
      y: initialOffset.y
    };
  }

  if (type === ItemTypes.RESIZE_RIGHT) {
    return {
      x: initialOffset.x,
      y: initialOffset.y
    };
  }

  return {
    x: currentOffset.x,
    y: initialOffset.y
  };
};

export const calcAppendWith = (
  initialOffset: XYCoord,
  currentOffset: XYCoord,
  type: ItemType,
  calendarType: CalendarType,
  sausageWidth?: number
) => {
  const width = CalendarTypeData[calendarType].width;

  if (!initialOffset || !currentOffset) {
    return undefined;
  }

  if (ItemTypes.RESIZE_LEFT === type) {
    return sausageWidth - (currentOffset.x - initialOffset.x) < width
      ? -sausageWidth + width
      : initialOffset.x - currentOffset.x;
  }

  if ([ItemTypes.RESIZE_RIGHT].includes(type)) {
    return sausageWidth - (initialOffset.x - currentOffset.x) < width
      ? -sausageWidth + width
      : currentOffset.x - initialOffset.x;
  }
};

export const calcNowPosition = (dateStart: moment.Moment, calendarType: CalendarType): number =>
  getSausageWidthOffset(dateStart, moment().subtract(1, 'day'), calendarType, true);

export const calcPositions = (
  data: GanttTableItem[],
  dateStart: moment.Moment,
  calendarType: CalendarType,
  isShowDateEndInit: boolean,
  workStatusDict: DictItem,
): PositionGanttItemsType => {
  const result = {};

  data.forEach((item, i) => {
    const isMilestone = checkMilestone(item);
    const isGroup = checkGroup(item);

    if (!item.dateStart || (!isMilestone && !item.dateEnd)) {
      return;
    }

    const itemDateStart = parseDate(item.dateStart);
    const itemDateEnd = parseDate(isMilestone ? item.dateEndFact : item.dateEnd);
    const itemDateEndFact = parseDate(item.dateEndFact);
    const itemDateEndInitial = parseDate(item.dateEndInitial);
    const itemDateEndSausage = isGroup
      ? (item.isFromOtherProject ? moment.max(compact([itemDateEnd, itemDateEndFact])) : parseDate(item.dateEndForRender))
      : parseDate(getDateEndForDuration(item, workStatusDict));

    const width = isMilestone ? 12 : getSausageWidth(itemDateStart, itemDateEndSausage, calendarType);

    result[item.id] = {};

    result[item.id].work = {
      id: item.id,
      x: getSausageWidthOffset(dateStart, itemDateStart.subtract(1, 'day'), calendarType, isMilestone) + (isMilestone ? (-width) / 2 : 0),
      y: SAUSAGE_HEIGHT * i,
      width: width,
    };

    if (isMilestone && itemDateEnd && itemDateEnd.diff(parseDate(item.dateStart), 'day') !== 0) {
      result[item.id].dateEndFact = {
        id: `${item.id}-dateEndFact`,
        x: getSausageWidthOffset(dateStart, itemDateEnd.subtract(1, 'day'), calendarType, isMilestone) + (-width / 2),
        y: SAUSAGE_HEIGHT * i,
        width: width,
      };
    }

    if (isShowDateEndInit && itemDateEndInitial && itemDateEndInitial.diff(itemDateEnd) !== 0) {
      result[item.id].dateEndInitial = {
        id: `${item.id}-dateEndInitial`,
        x: getSausageWidthOffset(dateStart, itemDateEndInitial.subtract(1, 'day'), calendarType, isMilestone) + (isMilestone ? (-width) / 2 : 0),
        y: SAUSAGE_HEIGHT * i,
        width: isMilestone ? width : 5,
      };
    }
  });

  return result;
};

export const getLinkType = (isFromStart: boolean, isToStart: boolean): LinkTypes => {
  const key = Object.entries(LinkTypesData).find(([_, value]) => {
    return value.from === isFromStart && value.to === isToStart;
  })[0];

  return (key as LinkTypes);
};

const getDurationInStartMonth = (start: moment.Moment, end: moment.Moment) => {
  const startMonth = start.clone().startOf('month');
  const endMonth = start.clone().endOf('month');

  return start.daysInMonth()
    - start.diff(startMonth, 'days')
    - (end.isSame(start, 'month') ? endMonth.diff(end, 'days') : 0);
};

const getDurationMonth = (start: moment.Moment, end: moment.Moment, width: number) => {
  const startClone = start.clone();
  let result = 0;

  while (startClone.isSameOrBefore(end)) {
    result += (getDurationInStartMonth(startClone, end) / startClone.daysInMonth()) * width;
    startClone.startOf('month').add(1, 'month');
  };

  return result;
};

let fakeIndex = 0;
export const getAutoFakeIndex = () => {
  return --fakeIndex;
};
export const setAutoFakeIndex = (newIndex) => {
  fakeIndex = newIndex;
};

export const isRemoved = (item: GanttTableItem): boolean => {
  return item.isRemove === true;
};

export const filterRemoved = (items: GanttTableItem[]): GanttTableItem[] => {
  return items.filter(not(isRemoved));
};

export const not = (func) => (...args) => !func(...args);