import React from 'react';
import { Modal } from 'react-bootstrap';
import ButtonRt from '@/components/Helper/ButtonRt'
import EscapePress from "@/components/EscapePress";

export default class ModalAsync extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      isShow: false,
      openPromise: null,
      content: null
    };
  }

  open = (content = null) => new Promise((reslove, reject) => {
    this.setState({
      content,
      isShow: true,
      openPromise: {
        reslove,
        reject
      }
    })
  });

  close = () => {
    this.setState({isShow: false});
  }

  reslove = (e?: any): void => {
    this.state.openPromise.reslove(e);
    this.setState({
      isShow: false,
    })
  }

  reject = (e?: any): void => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.state.openPromise.reject();
    this.setState({
      isShow: false,
    })
  }

  render() {
    const { title, children, okTitle, cancelTitle, size, isAlert, hideOk, hideCancel } = this.props;
    const { content, isShow } = this.state;

    return (
      <Modal show={isShow} onHide={isAlert ? this.reslove : this.reject} size={size} backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { content || children }
        </Modal.Body>
        <Modal.Footer>
          { isAlert ? (
            <ButtonRt onClick={this.reslove}>{ okTitle || 'ОК' }</ButtonRt>
          ) : (
            <div className='modal__buttons'>
              {!hideCancel && <ButtonRt type="outline" onClick={this.reject}>{cancelTitle || 'Отмена'}</ButtonRt>}
              {!hideOk && <ButtonRt onClick={this.reslove}>{okTitle || 'ОК'}</ButtonRt>}
            </div>
          ) }

        </Modal.Footer>
        <EscapePress action={isAlert ? this.reslove : this.reject}/>
      </Modal>
    )
  }
}
