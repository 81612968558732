import './style.scss';
import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';
import { Link } from 'react-router-dom';
import EditItem, { filterRemovedLinks, setIsRemove } from '@/components/Gantt/components/EditItem';
import GanttGraph from "@/components/Gantt/components/GanttGraph";
import GanttTable, { setFocusToTaskName } from '@/components/Gantt/components/GanttTable';
import { CalendarType, CalendarTypeData } from '@/components/Gantt/const';
import {
  AppendIcon,
  BackIcon,
  EditIcon,
  FullscreenIcon,
  PlanIcon,
  RemoveIcon,
  SaveIcon,
  UndoIcon
} from '@/components/Gantt/elements/Icons';
import useSplit from "@/components/Gantt/hooks/useSplit";
import useSyncScroll from "@/components/Gantt/hooks/useSyncScroll";
import { useGanttHistory } from '@/components/Gantt/util/historyUtil';
import {
  findAndUpdate,
  flatten,
  getAutoFakeIndex,
  getDateEndForDuration,
  getDateRangeSimple,
  getDuration
} from '@/components/Gantt/util/utils';
import { UserRight, WorkType } from '@/config/const';
import { useDeviceContext } from '@/context/DeviceContext';
import { prepareWork } from '@/pages/CreateProject/Blocks/EditBlock';
import TabButtons from '@/pages/Dashboard2/components/TabButtons';
import { isNotEmptyValues, momentToSelectDate, parseDate } from '@/utils';
import { getPathByType } from '@/utils/project';
import { isEmpty, uniq } from 'lodash';
import service from "@/services";
import { checkGroup, checkMilestone, checkTask } from '@/pages/CreateProject/Blocks/utils';
import DirtyFormModal from "@/elements/DirtyFormModal";
import MultipleEditModal from '@/components/Gantt/components/MultipleEditModal';
import Loader from '@/components/Loader';
import { useStateWithKey } from '@/utils/hooks';
import { useAppSelector } from '@/store';
import { useWeekendUtil } from '@/components/Gantt/util/dateUtil';

const dataTabButtons = Object.entries(CalendarTypeData)
  .map(([value, { label: title }]) => ({
    title,
    value
  }));

const getCalendarType = (days: number): CalendarType => {
  if (days > 180) {
    return CalendarType.YEAR;
  }

  if (days > 70) {
    return CalendarType.MONTH;
  }

  if (days > 30) {
    return CalendarType.WEEK;
  }

  return CalendarType.DAY;
};


export const createNewItem = (props = ({} as any)) => {
  const newId = getAutoFakeIndex();

  return prepareWork({
    id: newId,
    name: '(без названия)',
    duration: 1,
    typeId: WorkType.TASK,
    ...props,
  });
};


const GanttInside = ({
  projectData,
  work,
  link,
  expanded,
  setExpanded,
  onSave,
  readonly,
  isLoading,
  initCalendarType,
  isHideLink,
  isHideFullscreen,
  planLink,
  isAlwaysSmallScreen,
  isShowDateEndInit,
}: GanttProps) => {
  const workStatusDict = useAppSelector(state => state.dict.workStatus)
  const ganttHistory = useGanttHistory({
    work,
    link
  });
  const weekendUtil = useWeekendUtil();
  const [isShowCpm, setIsShowCpm] = useState(false);
  const [cpmIds, setCpmIds] = useState<number[]>([]);
  const { splitPercent, dragRef, dropRef } = useSplit(0.5);
  const [calendarType, setCalendarType] = useState(initCalendarType || CalendarType.DAY);
  const [openWorkId, setOpenWorkId] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [isEditOnlyLink, setIsEditOnlyLink] = useState(null);
  const [isFullscreen, setFullscreen] = useState(false);
  const scrollFirst = useRef(null);
  const scrollSecond = useRef(null);
  const graphRef = useRef(null);
  useSyncScroll(React.useRef([scrollFirst, scrollSecond]), { vertical: true, horizontal: false }, [isLoading]);
  const dataFlatten = useMemo(() => flatten(ganttHistory.data.work), [ganttHistory.data.work]);
  const { isMobile, isTablet, isSmallDesktop, isDesktop } = useDeviceContext();
  const [isUpdate, setIsUpdate] = useState(false);
  const [checked, setChecked] = useState<Record<number, boolean>>({});
  const isAnyChecked = Object.values(checked).includes(true);
  const [expandAllClick, setExpandAllClick] = useState(0);
  const [editModalData, setEditModalDataByKey, setEditModalData] = useStateWithKey({ isShow: false, setIsShow: val => {}, isHasTask: false, isHasMilestone: false });
  const [baseGroupId, setBaseGroupId] = useState(null);

  const isEditRight = projectData.edit && projectData.userRightCodes.includes(UserRight.EDIT_PROJECT);

  const setOpenWorkIdWrapper = (isOpenOnlyLink: boolean) => (id) => {
    setIsEditOnlyLink(isOpenOnlyLink);
    setOpenWorkId(id);
  }

  useEffect(() => {
    ganttHistory.reload({
      work,
      link
    });

    if (!baseGroupId) {
      setBaseGroupId(work.find(w => w.isBaseGroup)?.id);
    }
  }, [work, link]);

  useEffect(() => {
    if (ganttHistory.isHasChanges) {
      if (!isEmpty(cpmIds)) {
        setCpmIds([]);
      }

      if (isShowCpm) {
        setIsShowCpm(false);
      }
    }
  }, [ganttHistory.isHasChanges, isShowCpm]);

  useEffect(() => {
    if (isEmpty(ganttHistory.data.work) || !isShowCpm || !isEmpty(cpmIds)) {
      return;
    }

    const flattenWorks = flatten(ganttHistory.data.work);
    const workIds = flattenWorks.map(w => w.id);
    const curWorksLinks = ganttHistory.data.link.filter(l => workIds.includes(l.toId) && workIds.includes(l.fromId));

    service.post('/cpm/calculate', flattenWorks.map(w => ({
      id: w.id,
      dateStart: w.dateStart,
      dateEnd: getDateEndForDuration(w, workStatusDict),
      duration: checkMilestone(w) ? 0 : w.duration,
      groupId: w.workGroupId,
      prevIds: curWorksLinks.filter(l => l.toId === w.id && l.type === 'END_TO_START').map(w => w.fromId),
      nextIds: curWorksLinks.filter(l => l.fromId === w.id && l.type === 'END_TO_START').map(w => w.toId),
    }))).then(setCpmIds);
  }, [isShowCpm]);

  useEffect(() => {
    if (initCalendarType) {
      return;
    }

    const range = getDateRangeSimple(work, workStatusDict);
    if (range) {
      const days = getDuration(range.min, range.max);

      setCalendarType(getCalendarType(days));
    }

  }, [work]);

  const toggleFullscreen = () => {
    setFullscreen(old => !old);
  };

  const filteredHistoryData = () => {
    return {
      work: ganttHistory.data.work,
      link: ganttHistory.data.link,
      maxDepth: ganttHistory.data.maxDepth
    }
  }

  const save = () => {
    onSave(filteredHistoryData()).then(() => {
      setIsUpdate(false);
    });
  };

  const setDataWrapper = (setDataFunc, setLinkFunc = (x) => x) => {
    setIsUpdate(true);
    ganttHistory.updateData(setDataFunc, setLinkFunc);
  };

  const updateDataAllWrapper = (updateDataAllFunc) => {
    setIsUpdate(true);
    ganttHistory.updateDataAll(updateDataAllFunc);
  };

  const appendLatest = useCallback(() => {
    setOpenWorkId(null);

    let newItem;

    if (baseGroupId) {
      setDataWrapper(oldData => {
        return findAndUpdate(oldData, baseGroupId, (item) => {
          item.subRows ||= [];
          newItem = createNewItem({
            projectId: projectData?.projectId,
            projectVersionId: projectData?.id,
            workGroupId: baseGroupId,
          });
          item.subRows.push(newItem);

          return item;
        }, workStatusDict, weekendUtil);
      });

      setExpanded(oldExpanded => ({
        ...oldExpanded,
        [`${baseGroupId}`]: true
      }));
    } else {
      newItem = createNewItem({
        projectId: projectData?.projectId,
        projectVersionId: projectData?.id,
      });

      setDataWrapper(oldData => {
        return ([
          ...oldData,
          newItem
        ]);
      });
    }

    setTimeout(() => {
      setEditRowId(newItem.id);
      setFocusToTaskName(newItem.id);
    }, 100);
  }, [setDataWrapper, setOpenWorkId, baseGroupId]);

  const selectedWorkTypes = uniq(Object.entries(checked)
    .filter(([, isChecked]) => isChecked)
    .map(([key]) => +key)
    .map(id => {
      const work = dataFlatten.find(w => w.id === id);
      return work.typeId;
    }));
  const isSelectedAnyTaskOrMilestone = selectedWorkTypes.includes(WorkType.TASK)
    || selectedWorkTypes.includes(WorkType.MILESTONE)

  const onEdit = () => {
    setEditModalData({
      isShow: true,
      setIsShow: val => setEditModalDataByKey('isShow', val),
      isHasTask: selectedWorkTypes.includes(WorkType.TASK),
      isHasMilestone: selectedWorkTypes.includes(WorkType.MILESTONE),
    });
  }

  const editFromModal = (editData) => {
    const items = Object.entries(checked)
      .filter(([, isChecked]) => isChecked)
      .map(([key]) => +key)
      .filter(id => {
        const work = dataFlatten.find(w => w.id === id);
        return !work.isFromOtherProject && !checkGroup(work);
      });

    setIsUpdate(true);
    ganttHistory.updateDataAll(oldData => ({
      work: findAndUpdate(oldData.work, items, item => {
        const selectedStatusId = (checkMilestone(item) ? editData.milestoneStatusId : editData.taskStatusId);
        return {
          ...item,
          statusId: selectedStatusId || item.statusId,
          responsible: item.isExtreme ? null : (editData.responsible || item.responsible),
          responsibleRoleId: item.isExtreme ? null : (editData.responsibleRoleId || item.responsibleRoleId),
          dateEndFact: selectedStatusId ? null : item.dateEndFact,
          isLocalSaved: true,
        }
      }, workStatusDict, weekendUtil),
      link: oldData.link,
    }));

    setChecked({});
  }

  const onRemove = () => {
    const items = Object.entries(checked)
      .filter(([, isChecked]) => isChecked)
      .map(([key]) => +key)
      .filter(id => {
        const work = dataFlatten.find(w => w.id === id);
        if (!isEditRight && !work.isFromOtherProject || work.isRemoveDisabled) {
          return false;
        }
        return true;
      });

    ganttHistory.updateDataAll(oldData => ({
      work: findAndUpdate(oldData.work, items, setIsRemove, workStatusDict, weekendUtil),
      link: filterRemovedLinks(oldData.link, items)
    }));
    setChecked({});
  };

  const isAllNotExpanded = !expanded || isEmpty(Object.keys(expanded)) || Object.keys(expanded).every(key => !expanded[key]);
  const expandAll = () => {
    setExpandAllClick(prevState => ++prevState);
  }

  const scrollToNow = () => {
    graphRef.current?.scrollToNow();
  }

  return (
    <div className={cx("gantt mb-3", { fullscreen: isFullscreen || !readonly && !isAlwaysSmallScreen, isTablet, isMobile, isSmallDesktop, isDesktop })}>
      <div className="gantt_navbar__container">
        {!isHideLink && (
          readonly ? (
            <div className="gantt-navbar primary mb-2">
              <Link className="gantt-navbar-item" to={planLink}>
                <PlanIcon />
                Планирование
              </Link>
            </div>
          ) : (
            <div className="gantt-navbar primary mb-2">
              <Link className="gantt-navbar-item" to={`../`}>
                <BackIcon />
                В график работ
              </Link>
            </div>
          )
        )}

        <>
          <div className="gantt-navbar mb-2">
            {!readonly && <div className="gantt-navbar-item" onClick={save}>
              <SaveIcon/> Сохранить в карточку
            </div>}
            {!readonly && isEditRight && (
              <div className="gantt-navbar-item" onClick={appendLatest}>
                <AppendIcon/> Добавить
              </div>
            )}
            <div className='gantt-navbar-item' onClick={expandAll}>
              {isAllNotExpanded ? <><b>→</b> Развернуть все</> : <><b>↓</b> Свернуть все</>}
            </div>
            {!readonly &&
              <div className={cx("gantt-navbar-item", {disabled: !isAnyChecked || !isSelectedAnyTaskOrMilestone})} onClick={() => isAnyChecked && isSelectedAnyTaskOrMilestone && onEdit()}>
                <EditIcon/> Изменить несколько
              </div>}
            {!readonly &&
              <div className={cx("gantt-navbar-item", {disabled: !isAnyChecked})} onClick={() => isAnyChecked && onRemove()}>
                <RemoveIcon/> Удалить
              </div>}
          </div>
          {!readonly && <div className="gantt-navbar mb-2">
            <div className={cx("gantt-navbar-item", {disabled: ganttHistory.isUndoDisabled})} title="Отменить"
                 onClick={ganttHistory.undo}>
              <UndoIcon/>
            </div>
            <div className={cx("gantt-navbar-item", {disabled: ganttHistory.isRendoDisabled})} title="Повторить"
                 onClick={ganttHistory.rendo}>
              <UndoIcon style={{transform: 'scale(-1, 1)'}}/>
            </div>
          </div>}
        </>

        <TabButtons className="gantt mb-2" data={dataTabButtons} value={calendarType} onChange={setCalendarType} />

        <div className="gantt-navbar mb-2" style={{padding: 3}}>
          <div
            className={cx("gantt-navbar-item")}
            onClick={scrollToNow}
            style={{padding: '5px 7px'}}
          >
            Сегодня
          </div>
        </div>

        {(readonly || isAlwaysSmallScreen) && !isHideFullscreen && (
          <div className="gantt-navbar mb-2">
            <div className="gantt-navbar-item" onClick={toggleFullscreen}>
              <FullscreenIcon /> {isFullscreen ? 'Выйти из полноэкранного режима' : 'Во весь экран'}
            </div>
          </div>
        )}

        <div className="gantt-navbar mb-2" style={{padding: 3}}>
          <div
            className={cx("gantt-navbar-item", {active: isShowCpm}, {disabled: ganttHistory.isHasChanges})}
            onClick={() => setIsShowCpm(prev => !prev)}
            style={{padding: '5px 7px'}}
          >
            Критический путь
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader isMini />
      ) : (
        <div className="gantt-main__container">
          <div className="split__container" ref={dropRef}>
            <div className="split__item" style={{ flex: splitPercent }}>
              <div className="gantt-panel__container left custom-scrollbar">
                <GanttTable
                      projectData={projectData}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      expandAllClick={expandAllClick}
                      isAllNotExpanded={isAllNotExpanded}
                      expandAll={expandAll}
                      data={ganttHistory.data.work}
                      link={ganttHistory.data.link}
                      maxDepth={ganttHistory.data.maxDepth}
                      setData={setDataWrapper}
                      openWorkId={openWorkId}
                      setOpenWorkId={setOpenWorkIdWrapper(false)}
                      editRowId={editRowId}
                      setEditRowId={setEditRowId}
                      readonly={readonly}
                      isEditRight={isEditRight}
                      checked={checked}
                      setChecked={setChecked}
                      dataFlatten={dataFlatten}
                      scrollContainer={scrollFirst}
                    />
              </div>
            </div>
            <div className="split_splitter" ref={dragRef}>:</div>
            <div className="split__item" style={{ flex: 1 - splitPercent }}>
              <div className="gantt-panel__container custom-scrollbar" ref={scrollSecond}>
                <GanttGraph
                  ref={graphRef}
                  scrollRef={scrollSecond}
                  data={ganttHistory.data.work}
                  expanded={expanded}
                  setData={setDataWrapper}
                  link={ganttHistory.data.link}
                  isShowCpm={isShowCpm}
                  cpmIds={cpmIds}
                  calendarType={calendarType}
                  setOpenWorkId={setOpenWorkIdWrapper(true)}
                  isEdit={!readonly && isEditRight}
                  isEditLink={!readonly && isEditRight}
                  isShowDateEndInit={isShowDateEndInit}
                />
              </div>
            </div>
          </div>
          {isNotEmptyValues(openWorkId) && (
            <div className={cx("gantt-item-edit__container", { isMobile })} key={openWorkId}>
              <EditItem
                openWorkId={openWorkId}
                setOpenWorkId={setOpenWorkId}
                data={dataFlatten}
                links={ganttHistory.data.link}
                updateData={updateDataAllWrapper}
                readonly={readonly}
                linkReadonly={readonly || !isEditRight}
                isOnlyLinkEdit={isEditOnlyLink}
                projectData={projectData}
                setExpanded={setExpanded}
              />
            </div>
          )}
        </div>
      )}
      <DirtyFormModal
        isDirty={isUpdate && !ganttHistory.isUndoDisabled}
        preventRedirectOnSave
        onSave={async () => {await save()}} />
      <MultipleEditModal
        editModalData={editModalData}
        onSave={editFromModal}
      />
    </div>
  );
};

export interface GanttProps {
  projectData: Project;
  work: Work[];
  expanded: any;
  setExpanded: UpdateFunc<any>;
  link: GanttTableLink[];
  onSave: (data: any) => Promise<void>;
  readonly: boolean,
  isLoading: boolean,
  initCalendarType: CalendarType,
  isHideLink?: boolean;
  isHideFullscreen?: boolean;
  isHideUndoRendo?: boolean;
  planLink?: string;
  isAlwaysSmallScreen?: boolean;
  isShowDateEndInit?: boolean;
};

const Gantt = (props: GanttProps) => {
  const { isDesktop } = useDeviceContext();

  if (!props.projectData) {
    return null;
  }

  return (
    <DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
      <GanttInside
        { ...props }
        isShowDateEndInit={props.isShowDateEndInit !== false}
        planLink={props.planLink
          || `/${getPathByType(props.projectData.projectTypeCode) || 'project'}/${props.projectData.id}/work/gantt?projectVersionId=${props.projectData.id}`}
      />
    </DndProvider>
  );
};

export default Gantt;