import { dashboardExportXlsx } from "@/pages/Dashboard2/dashboardExportXlsx";
import { getDictByCode } from "@/utils";

const getReportStatusText = (context, code) => {
  return getDictByCode(context.dict.reportStatus, code)?.name || '';
}

const getProjectDirectionText = (context, code) => {
  return getDictByCode(context.dict.projectDirection, code)?.name || '';
}

export const topReportExcelExport = (
  data, {
    type = undefined,
    name = undefined,
    context = undefined
  } = {}) => {
  const columns: any[] = [
    {
      text: `№ п/п\n(Всего: ${data.length})`,
      dataField: `index`,
      excel: {
        width: 20,
      },
    },
    {
      text: 'ID',
      dataField: `projectId`,
      excel: {
        width: 7,
      },
    },
    {
      text: 'Наименование',
      dataField: `name`,
      excel: {
        width: 25,
      },
    },
  ];

  if (['PSR'].includes(type)) {
    columns.push({
        text: 'Статус',
        dataField: `projectStatusName`,
        excel: {
          width: 20,
        },
      },
      {
        text: 'ПСР-Лидер',
        dataField: `psrLeader`,
        excel: {
          width: 35,
        },
      },
      {
        text: 'Направление проекта',
        dataField: `projectDirectionCode`,
        excel: {
          formatter: cell => getProjectDirectionText(context, cell),
          width: 35,
        },
      }
    );
  }

  columns.push({
      text: 'Руководитель',
      dataField: `projectLeader`,
      excel: {
        width: 35,
      },
    },
    {
      text: 'Сегмент заказчика',
      dataField: `segmentNames`,
      excel: {
        width: 20,
      },
    },
    {
      text: 'Отклонения "Сводный"',
      dataField: `reportConsolidateStatusCode`,
      excel: {
        formatter: cell => getReportStatusText(context, cell),
        width: 25,
      },
    },
    {
      text: 'Отклонения "Сроки"',
      dataField: `reportTimingStatusCode`,
      excel: {
        formatter: cell => getReportStatusText(context, cell),
        width: 25,
      },
    },
    {
      text: 'Отклонения "Бюджет"',
      dataField: `reportBudgetStatusCode`,
      excel: {
        formatter: cell => getReportStatusText(context, cell),
        width: 25,
      },
    },
    {
      text: 'Отклонения "Риски"',
      dataField: `reportRiskStatusCode`,
      excel: {
        formatter: cell => getReportStatusText(context, cell),
        width: 25,
      },
    });

  if (['PSR'].includes(type)) {
    columns.push({
        text: 'Комментарий к статус-отчету',
        dataField: `reportConsolidateComment`,
        excel: {
          width: 35,
        },
      },
      {
        text: `${type === 'PSR' ? 'Эскалация / Бюджетные вопросы по проекту' : 'Эскалация'}`,
        dataField: `reportEscalationComment`,
        excel: {
          width: 35,
        },
      }
    );
  }

  if (['TOP', 'KEY'].includes(type)) {
    columns.push({
        text: 'Комментарий к статус-отчету',
        dataField: `reportConsolidateComment`,
        excel: {
          formatter: (cell, row) =>
            `${row.reportEscalationComment ? row.reportEscalationComment + '\n' : ''}` +
            `${row.reportConsolidateComment ? row.reportConsolidateComment : ''}`,
          width: 35,
        },
      },
      {
        text: 'Комментарий корпоративного проектного офиса',
        dataField: `reportProjectOfficeComment`,
        excel: {
          width: 45,
        },
      }
    );
  }

  dashboardExportXlsx(
    data,
    columns, {
      name: name,
      context: {
        context
      }
    })
}