import cx from 'classnames';
import { isEmpty, isObject } from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { modalHide, modalShow } from '@/actions/modal';
import ButtonRt from '@/components/Helper/ButtonRt';
import ModalWindow from '@/components/Modal';
import { IModalState } from '@/reducers/Modal';

interface ICommonModalButton {
  title: string;
  closeAfterClick?: boolean;
  onClick?: () => void;
  isHide?: boolean;
}

export interface ICommonModalOptions {
  title?: string;
  isHideButtonClose?: boolean;
  buttonCloseTitle?: string;
  buttons?: ICommonModalButton[];
  className?: string;
  centered?: boolean;
  animation?: boolean;
}

interface ICommonModalProps {
  modalHide: () => void;
  modalShow: (message: string) => void;
  Modal: IModalState;
}

class CommonModal extends React.Component<ICommonModalProps, any> {

  hideModal = () => {
    this.props.modalHide();
  };

  showModal = () => {
    this.props.modalShow(this.props.Modal.message);
  };

  getModalInfo = () => {
    let info = this.props.Modal.message;

    if (!isObject(info)) {
      info = {
        message: info
      };
    }

    return {
      title: this.props.Modal.options?.title || 'Ошибка',
      ...info
    };
  };

  render() {
    const { title, message } = this.getModalInfo();
    const { options } = this.props.Modal;

    const footer = (
      <Modal.Footer>
        {options.buttons?.filter(button => !button.isHide).map((button, i) => (
          <ButtonRt key={`modal-button-${i}`} className="modalButton" onClick={async () => {
            await button.onClick?.();
            if (button.closeAfterClick) {
              this.hideModal();
            }
          }}>
            {button.title}
          </ButtonRt>
        ))}
        {!options.isHideButtonClose && (
          <ButtonRt className="modalButton" onClick={this.hideModal}>
            {options.buttonCloseTitle || 'Закрыть'}
          </ButtonRt>
        )}
      </Modal.Footer>
    );

    return (
      <ModalWindow
        className={cx("modal-new-line", { [options.className]: options.className })}
        show={!isEmpty(message)}
        onHide={() => this.hideModal()}
        title={title}
        bodyText={message}
        size="lg"
        footer={footer}
        centered={options.centered === false ? false : true}
        animation={options.animation === false ? false : true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  Modal: state.Modal,
  modalMessage: state.Modal.message
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  modalHide: () => dispatch(modalHide()),
  modalShow: (message) => dispatch(modalShow(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonModal);
