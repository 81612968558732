import React, { useState } from 'react';

import WorkTable from '@/pages/Dashboard2/DashboardProjectStatus/WorkList/WorkTable';
import GroupHeaderItem from '@/pages/Dashboard2/DashboardProjectStatus/WorkList/GroupHeaderItem';

const WorkGroupItem = ({ group, projectType }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dashboard-work__container">
      <GroupHeaderItem group={group} setIsOpen={setIsOpen} isOpen={isOpen} projectType={projectType} />
      { isOpen && <WorkTable works={group.children} projectType={projectType} /> }
    </div>
  );
}

export default WorkGroupItem;
