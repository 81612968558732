import React, { CSSProperties, forwardRef, Fragment, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import Checkbox from "@/elements/Checkbox";
import { getDict } from "@/utils";
import ButtonRt from "@/components/Helper/ButtonRt";
import Input from "@/elements/Input";
import i18n from "i18next";
import { useAppSelector } from '@/store';
import { useDeviceContext } from "@/context/DeviceContext";
import cx from "classnames";

type DocModalProps = {
  isByOwner,
  fileList,
  isDisabledSelect,
  addLink,
  selectedFile,
  selectedOldFile
}

const DocModal = forwardRef((props: DocModalProps, ref) => {
  const [showMainModal, setShowMainModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  const [newUrlName, setNewUrlName] = useState('');
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [filter, setFilter] = useState(null);
  const dict = useAppSelector(state => state.dict);
  const { isMobile } = useDeviceContext();

  const {
    isByOwner,
    fileList,
    isDisabledSelect,
    addLink,
    selectedFile,
    selectedOldFile
  } = props;

  const projectFiles = fileList?.filter(file => !file.externalLink)
    .map(file => ({ ...file, initialTypeSectionName: getDict(dict.fileSection, file.initialTypeSectionId) }));
  const projectLinks = fileList?.filter(file => !!file.externalLink)
    .map(file => ({ ...file, initialTypeSectionName: getDict(dict.fileSection, file.initialTypeSectionId) }));
  const isAnyFilesInProject = (projectFiles?.length > 0);
  const isAnyLinksInProject = (projectLinks?.length > 0);
  
  const closeMainModal = () => {
    setShowMainModal(false);
    setFilter(null);
    setCheckedFiles([]);
  }
  const closeLinkModal = () => {
    setNewUrl('');
    setNewUrlName('');
    setShowLinkModal(false);
  }
  const openMainModal = () => {
    setShowMainModal(true);
  }
  const openLinkModal = () => {
    setShowLinkModal(true);
  }

  const handleCheckboxChange = (file) => {
    if (checkedFiles.includes(file)) {
      setCheckedFiles((state) => {
        return state.filter(value => value !== file);
      });
    } else {
      setCheckedFiles((state) => [...state, file]);
    }
  }

  useImperativeHandle(ref, () => ({
    open() {
      openMainModal();
    },
    close() {
      closeMainModal();
    },
  }));

  const footerStyleBase: CSSProperties = {justifyContent: 'flex-start', gap: '1rem', flexWrap: 'nowrap'};
  const footerStyle: CSSProperties = isByOwner ? footerStyleBase : {...footerStyleBase, borderTop: undefined};
  const footerStyleMobile: CSSProperties = {justifyContent: 'center', flexWrap: 'wrap'};

  const filterFile = (file) => !filter || file.name.toLowerCase().includes(filter.toLowerCase())
    || file.initialTypeSectionName.toLowerCase().includes(filter.toLowerCase())

  return <Fragment>
    <Modal show={showMainModal} onHide={() => closeMainModal()} size={isByOwner ? 'lg' : undefined} className='doc-modal' backdrop='static'>

      <Modal.Header closeButton>
        <Modal.Title>Документы</Modal.Title>
      </Modal.Header>
      {isByOwner && <Modal.Body style={{paddingLeft: '0', paddingRight: '0', paddingBottom: '0'}}>
        <Fragment>
          <Input
            titleClassName='doc-modal-filter'
            label='Поиск'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            isClearable
          />
          <div className='doc-modal-fileList'>
            {isAnyFilesInProject ? projectFiles
              .filter(filterFile)
              .map(file => (
                <Checkbox key={file.id}
                          onChange={() => handleCheckboxChange(file.id)}
                          checked={checkedFiles.includes(file.id)}>
                  {file.name} ({file.initialTypeSectionName})
                </Checkbox>
              )) : <div>Нет файлов</div>}
          </div>

          <Modal.Title style={{marginLeft: isMobile ? '0' : '1rem', marginBottom: isMobile ? '0.6rem' : '0'}}>
            Ссылки
          </Modal.Title>
          {!isMobile && <hr/>}

          <div className='doc-modal-fileList'>
            {isAnyLinksInProject ? projectLinks
              .filter(filterFile)
              .map(file => (
                <Checkbox key={file.id}
                          onChange={() => handleCheckboxChange(file.id)}
                          checked={checkedFiles.includes(file.id)}>
                  {file.name} ({file.initialTypeSectionName})
                </Checkbox>
              )) : <div>Нет ссылок</div>}
          </div>
        </Fragment>
      </Modal.Body>}
      <Modal.Footer style={isMobile ? footerStyleMobile : footerStyle}>
        {isByOwner && <div
          className={`width-100 form-group input-file-felix ${isDisabledSelect || checkedFiles.length === 0 
            ? 'disable' : ''}`}>
          <label className="width-100 rt-custom-file-label" style={{margin: '0px'}} htmlFor="inputGroupReuseInModal">
            Переиспользовать
          </label>
          <input
            disabled={isDisabledSelect || checkedFiles.length === 0}
            onClick={(e) => {
              selectedOldFile(fileList.filter(item => checkedFiles.includes(item.id)));
              closeMainModal();
            }}
            type='button'
            id="inputGroupReuseInModal"
            multiple
            aria-describedby="inputGroupFileAddon04"
          />
        </div>}

        <div
          className={`width-100 form-group input-file-felix ${isDisabledSelect ? 'disable' : ''}`}>
          <label className="width-100 rt-custom-file-label" style={{margin: '0px'}} htmlFor="inputGroupFileInModal">
            Загрузить файл
          </label>
          <input
            disabled={isDisabledSelect}
            onChange={(e) => {
              selectedFile(e.target.files);
              closeMainModal();
            }}
            onClick={(e: any) => e.target.value = null}
            type="file"
            id="inputGroupFileInModal"
            multiple
            aria-describedby="inputGroupFileAddon04"
          />
        </div>

        <div
          className={`width-100 form-group input-file-felix ${isDisabledSelect ? 'disable' : ''}`}>
          <label className="width-100 rt-custom-file-label" style={{margin: '0px'}} htmlFor="inputGroupLinkInModal">
            Добавить ссылку
          </label>
          <input
            disabled={isDisabledSelect}
            onClick={(e) => {
              closeMainModal();
              openLinkModal();
            }}
            type='button'
            id="inputGroupLinkInModal"
            multiple
            aria-describedby="inputGroupFileAddon04"
          />
        </div>
      </Modal.Footer>
    </Modal>

    <Modal show={showLinkModal} onHide={() => closeLinkModal()} size={isByOwner ? 'lg' : undefined} backdrop='static'>

      <Modal.Header closeButton>
        <Modal.Title>Добавить ссылку</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <div className={cx('row gap-2 marginRight-6', {'flex-nowrap': !isMobile})} >
            <div className={isMobile ? 'col-md-12' : 'col-md-6'}>
              <Input
                label={i18n.t('workDataRequest.urlList.title')}
                value={newUrlName}
                onChange={e => setNewUrlName(e.target.value)}
                name="work_url_add_title"
              />
            </div>
            <div className={isMobile ? 'col-md-12' : 'col-md-6'}>
              <Input
                label={i18n.t('workDataRequest.urlList.url')}
                value={newUrl}
                onChange={e => setNewUrl(e.target.value)}
                name="work_url_add_url"
              />
            </div>
          </div>
          <div className='col-md-12'>
            <ButtonRt disabled={isDisabledSelect} type="outline" onClick={() => {
              addLink(newUrl, newUrlName);
              closeLinkModal();
            }}>
              Готово
            </ButtonRt>
          </div>
        </Fragment>
      </Modal.Body>
    </Modal>
  </Fragment>
})

export default DocModal;