export default {
  SYSTEM: {
    translation: {
      'agreementTextOrganization': 'Акционерному обществу «Почта России»',
    },
  },
  PROJECT: {
    translation: {
    }
  },
  PROGRAM: {
    translation: {
    }
  },
  PORTFOLIO: {
    translation: {
    }
  },
}