import React, { useEffect } from 'react';
import { Modal } from "react-bootstrap";
import ButtonRt from "@/components/Helper/ButtonRt";
import { WorkWithChecked, checkGroup, workGroupSort } from '@/pages/CreateProject/Blocks/utils';
import { useStateWithKey } from "@/utils/hooks";
import { compact, isEmpty } from "lodash";
import i18n from "i18next";
import Checkbox from "@/elements/Checkbox";
import { Link } from "react-router-dom";
import { formatDate, formatDays, getDict, getDictObj, sortDate } from "@/utils";
import cx from "classnames";
import { dateEndClass, dateStartClass, rowClasses } from "@/pages/CreateProject/Blocks/TableResultWorks";
import { FORMAT_DATE } from "@/config/const";
import Table from "@/components/Table";
import { useAppSelector } from '@/store';
import ProjectSearch from "@/components/ProjectSearch";
import { workDataLoader } from '@/pages/CreateProject/Blocks/WorkList';

const defaultState = () => ({
  project: null,
  projectWorks: [] as WorkWithChecked[],
});

interface GetWorksModalProps {
  isShow: boolean;
  onSelect: (works: Work[]) => void;
  onCancel: () => void;
  projectId: number;
}

const GetWorksModal = ({
  isShow,
  onSelect,
  onCancel,
  projectId,
}: GetWorksModalProps) => {
  const [state, setStateByKey, setState] = useStateWithKey(defaultState());

  const getProjectWorks = () => {
    if (!state.project?.value) {
      return;
    }

    workDataLoader(state.project.value)
      .then((works) => setStateByKey('projectWorks',
        workGroupSort(works.filter(w => w.isBaseGroup || !w.isSystem))));
  };

  useEffect(getProjectWorks, [state.project?.value, setStateByKey]);

  const onOk = async () => {
    const works = selectedWorks();
    setState(defaultState());
    onSelect(works);
  };

  const onCancelWrapper = () => {
    setState(defaultState());
    onCancel();
  };

  const isCheckedAll = state.projectWorks.every(w => w.isChecked);

  const onToggleWork = (workId) => {
    setStateByKey('projectWorks', state.projectWorks.map(pw => ({
      ...pw,
      isChecked: pw.id === workId ? !pw.isChecked : pw.isChecked,
    })));
  };

  const onToggleAll = () => {
    setStateByKey('projectWorks', state.projectWorks.map(pw => ({
      ...pw,
      isChecked: !isCheckedAll,
    })));
  }

  const selectedWorks = () => {
    return state.projectWorks.filter(pw => pw.isChecked);
  };

  return (
    <Modal show={isShow} onHide={onCancelWrapper} size="xl" backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>Добавление графика работ из других карточек</div>
          <h6>(График работ будут добавлен во все версии проекта)</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectSearch
          onChange={(val) => setStateByKey('project', val)}
          value={state.project}
          label="Карточка источник"
          isClearable={true}
          excludeIds={[projectId]}
          isPortal
        />

        {state.project?.value && (
          <TableWorksSelect
            data={state.projectWorks}
            isCheckedAll={isCheckedAll}
            onToggleWork={onToggleWork}
            onToggleAll={onToggleAll}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='modal__buttons'>
          <ButtonRt type="outline" onClick={onCancelWrapper}>Закрыть</ButtonRt>
          <ButtonRt disabled={isEmpty(selectedWorks())} onClick={onOk}>Добавить</ButtonRt>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default GetWorksModal;

interface TableWorksSelectProps {
  data: Work[];
  isCheckedAll: boolean;
  onToggleWork: (id: number) => void;
  onToggleAll: () => void;
}

const TableWorksSelect = ({ data, isCheckedAll, onToggleWork, onToggleAll }: TableWorksSelectProps) => {
  const dict = useAppSelector(state => state.dict);

  const detailColumns = compact([
    {
      dataField: 'id',
      text: '',
      formatExtraData: {
        onToggleWork,
        isCheckedAll,
        onToggleAll,
      },
      headerFormatter: () => {
        return (
          <>
            <div className="flex-inline-center-vertical">
              <Checkbox
                checked={isCheckedAll}
                onChange={onToggleAll}
                tooltip="Выделить все работы для удаления"
              />
            </div>
          </>
        );
      },
      formatter: (cell, row: WorkWithChecked, _, { onToggleWork }) => {
        return (
          <div className="flex-inline-center-vertical">
            <Checkbox
              checked={row.isChecked || false}
              onChange={() => onToggleWork(row.id)}
              tooltip="Выбрать работу"
            />
          </div>
        );
      },
    },
    {
      dataField: 'name',
      text: i18n.t('workDataRequest.name'),
      sort: true,
      headerStyle: () => {
        return { minWidth: '250px' };
      },
      formatter: (cell, row: WorkWithChecked) => {
        const isIndentName = row.workGroupId !== null && !checkGroup(row);

        return (
          <div className="flex-inline-center-vertical">
            <Link to={`/${getDictObj(dict.types, row.projectTypeId).code.toLowerCase()}/${row.projectVersionId}/work/${row.id}`}
                  target="_blank"
                  className={cx({
                    'work-group__children': isIndentName,
                  })}
            >
              {cell}
            </Link>
          </div>
        );
      },
    },
    {
      dataField: 'statusId',
      text: i18n.t('workDataRequest.statusId'),
      sort: true,
      headerStyle: () => {
        return { left: '250px' };
      },
      style: () => {
        return { left: '250px' };
      },
      formatter: cell => getDict(dict.workStatus, cell),
    },
    {
      dataField: 'responsible.displayName',
      text: i18n.t('workDataRequest.responsible'),
      sort: true,
    },
    {
      dataField: 'dateStart',
      text: i18n.t('workDataRequest.dateStart'),
      sort: true,
      sortFunc: sortDate,
      formatExtraData: dict?.workStatus,
      formatter: (cell, row, _, workStatusDict) => {
        return (
          <div className={cx(dateStartClass(cell, row, workStatusDict))}>
            {formatDate(cell, FORMAT_DATE)}
          </div>
        );
      }
    },
    {
      dataField: 'dateEnd',
      text: i18n.t('workDataRequest.dateEnd'),
      sort: true,
      sortFunc: sortDate,
      formatExtraData: dict?.workStatus,
      formatter: (cell, row, _, workStatusDict) => {
        return (
          <div className={cx(dateEndClass(cell, row, workStatusDict))}>
            {formatDate(cell, FORMAT_DATE)}
          </div>
        );
      }
    },
    {
      dataField: 'dateEndFact',
      text: i18n.t('workDataRequest.dateEndFact'),
      sort: true,
      sortFunc: sortDate,
      formatExtraData: dict?.workStatus,
      formatter: (cell, row, _, workStatusDict) => {
        return (
          <div className={cx(dateEndClass(cell, row, workStatusDict))}>
            {formatDate(cell, FORMAT_DATE)}
          </div>
        );
      }
    },
    {
      dataField: 'duration',
      text: i18n.t('workDataRequest.duration'),
      sort: true,
      formatter: formatDays
    },
  ]);

  return (
    <Table
      keyField='id'
      data={data}
      columns={detailColumns}
      rowClasses={rowClasses}
    />
  );
};