import { isEmpty, uniqBy } from "lodash";
import React, { memo, useEffect, useState } from "react";
import Gantt from "@/components/Gantt";
import { CalendarType } from "@/components/Gantt/const";
import { flatten, getDateEndForDuration, getGroupIds, getLinkKey, groupWorks } from '@/components/Gantt/util/utils';
import { prepareWork, prepareWorkSave } from "@/pages/CreateProject/Blocks/EditBlock";
import { fillWorksStatus, getProgress } from "@/pages/CreateProject/Blocks/Gantt";
import service from "@/services";
import { getDictObj, parseDate } from '@/utils';
import { useAppSelector } from '@/store';
import { useDispatch } from "react-redux";
import { getBasicNewProjectData } from "@/actions/getBasicNewProjectData";
import { isWorkExpired, isWorkRemoveDisabled } from '@/pages/CreateProject/Blocks/utils';
import { sendFilesToServer } from '@/components/Doc/SaveDoc';
import { decLoadCount, incLoadCount } from '@/actions/actionHelper';
import { useCheckRight } from '@/utils/hooks';
import { workDataLoader } from '@/pages/CreateProject/Blocks/WorkList';
import { fixGroup } from '@/components/Gantt/util/linkUtil';
import { useWeekendUtil, WeekendUtilType } from '@/components/Gantt/util/dateUtil';

export const updateWorkGanttColorClasses = (item: GanttTableItem) => {
  item.customClass = [
    'STATUS_DICT',
    isWorkExpired(item) ? 'EXPIRED' : '',
    item.status?.code,
  ];
}

export const prepareWorksForGantt = (
  works: Work[], projectId: number,
  weekendUtil: WeekendUtilType,
  workStatusDict: DictItem,
  customProps: ((_: Work) => any) = (_ => ({}))
): [GanttTableItem[], Record<number, boolean>] => {
  const dataWithStatus = fillWorksStatus(works, workStatusDict);
  const baseGroupId = dataWithStatus.find(w => w.isBaseGroup)?.id;
  const groupIds = getGroupIds(dataWithStatus);
  const groupIdsArr = Object.keys(groupIds).map(item => +item);

  const preparedWorks = dataWithStatus
    .map(item => prepareWork({
      ...item,
      isFromOtherProject: item.projectId && item.projectId !== projectId,
      workGroupId: item.projectId && item.projectId !== projectId && !groupIdsArr.includes(item.workGroupId)
        ? baseGroupId : item.workGroupId,
      progress: getProgress(dataWithStatus, item),
      dateStartPrev: parseDate(item.dateStart),
      dateEndPrev: parseDate(getDateEndForDuration(item, workStatusDict)),
    }, false))
    .map(item => ({ ...item, ...customProps(item) }));

  const groupedWorks = fixGroup(groupWorks(preparedWorks), weekendUtil, workStatusDict);
  const flattened = flatten(groupedWorks);
  flattened.forEach(updateWorkGanttColorClasses);

  return [groupedWorks, groupIds];
};

export interface GanttWithLoadProps {
  projectData: Project;
  works?: Work[];
  links?: GanttTableLink[];
  onWorksLoad?: (works: Work[]) => void;
  isLoad?: boolean;
  readonly?: boolean;
  filter?: any;
  initCalendarType?: CalendarType;
  isHideLink?: boolean;
  isHideFullscreen?: boolean;
  isShowDateEndInit?: boolean;
};

const GanttWithLoad = memo(({
  projectData,
  works = undefined,
  links = undefined,
  onWorksLoad,
  isLoad = undefined,
  readonly,
  filter = undefined,
  initCalendarType = undefined,
  isHideLink = false,
  isHideFullscreen,
  isShowDateEndInit,
}: GanttWithLoadProps) => {
  const dispatch = useDispatch();
  const checkRight = useCheckRight();
  const weekendUtil = useWeekendUtil();
  const getNewProjectData = (id) => dispatch(getBasicNewProjectData(id));

  const [isLoadLocal, setIsLoadLocal] = useState(false);
  const [work, setWork] = useState<Work[]>([]);
  const [link, setLink] = useState<GanttTableLink[]>([]);
  const [expanded, setExpanded] = useState({});
  const [workLinkTypeDict, workStatusDict, projectStatusDict, fileTypeDict] = useAppSelector(state => [
    state.dict.workLinkType,
    state.dict.workStatus,
    state.dict.status,
    state.dict.fileType,
  ]);

  const initData = async (projectData, works, links, filter, workLinkTypeDict, workStatusDict, projectStatusDict) => {
    if (!works) {
      works = await workDataLoader(projectData.id, {
        params: JSON.stringify({
          withLinked: true,
          ...(filter || {}),
        })
      });

      if (onWorksLoad) {
        onWorksLoad(works);
      }
    }

    const [groupedWorks, groupIds]  = prepareWorksForGantt(works,
      projectData.projectId, weekendUtil, workStatusDict, w => ({
        isRemoveDisabled: isWorkRemoveDisabled(w, workStatusDict, projectStatusDict, checkRight, projectData),
        isLocalSaved: false,
      }));
    setWork(groupedWorks);
    setExpanded(groupIds);

    if (!links) {
      links = await service.get(`/work/link/project/${projectData.id}`);
    }
    setLink(links.map(item => ({
      ...item,
      type: getDictObj(workLinkTypeDict, item.typeId).code,
    })));
  };

  useEffect(() => {
    if (!projectData?.id || !workLinkTypeDict?.isLoad || !workStatusDict?.isLoad) {
      return;
    }

    initData(projectData, works, links, filter, workLinkTypeDict, workStatusDict, projectStatusDict).then(() => setIsLoadLocal(true));
  }, [projectData?.id, works, links, workLinkTypeDict, workStatusDict, filter]);

  const onSave = async (data) => {
    incLoadCount();
    try {
      const preparedWorks = flatten(data.work).map((work, index) => {
        return prepareWorkSave({
          ...work,
          projectVersionId: work.projectVersionId ? work.projectVersionId : projectData.id,
          sort: index + 1
        });
      });

      const otherProjectWorks = preparedWorks.filter(item => item.isFromOtherProject);

      const linkWorks = otherProjectWorks.filter(item => !item.isRemove).map(item => ({
        projectId: projectData.projectId,
        staticWorkId: item.workId || item.id,
        sort: item.sort,
      }));

      const unlinkWorks = otherProjectWorks.filter(item => item.isRemove).map(item => ({
        projectId: projectData.projectId,
        staticWorkId: item.workId || item.id,
      }));

      if (!isEmpty(linkWorks)) {
        await service.post(`/work/linkWorks/project/${projectData.projectId}`, linkWorks);
      }

      if (!isEmpty(unlinkWorks)) {
        await service.post(`/work/unlinkWorks/project/${projectData.projectId}`, unlinkWorks);
      }

      const notRemovedWorkIds = preparedWorks.filter(item => !item.isRemove).map(item => item.id);
      const currentProjectWorks = preparedWorks.filter(item => !item.isFromOtherProject);

      const ganttSaveData = {
        projectId: projectData.projectId,
        projectVersionId: projectData.id,
        link: uniqBy<GanttTableLink>(data.link, getLinkKey)
          .filter(link => [link.fromProjectId, link.toProjectId].includes(projectData.projectId)
            && (notRemovedWorkIds.includes(link.fromId) && notRemovedWorkIds.includes(link.toId))),
        work: currentProjectWorks.map(w => ({...w, fileItems: undefined})),
      };

      await service.put('/work/gantt/save', ganttSaveData);
      await Promise.all(currentProjectWorks
        .filter(w => !isEmpty(w.fileItems))
        .map(w => sendFilesToServer(w.projectVersionId, w.workId, w.fileItems, fileTypeDict, projectData.isAgreeByMilestone)));
      service.put(`/work/gantt/validateProject/${projectData.id}`, undefined, {
        modalOptions: {
          title: 'Предупреждение'
        }
      });
      getNewProjectData(projectData.id);
      await initData(projectData, null, null, filter, workLinkTypeDict, workStatusDict, projectStatusDict);
    } finally {
      decLoadCount();
    }
  };

  return (
    <Gantt
      work={work}
      link={link}
      expanded={expanded}
      setExpanded={setExpanded}
      onSave={onSave}
      readonly={readonly}
      isLoading={!isLoadLocal || (isLoad !== undefined && !isLoad)}
      projectData={projectData}
      initCalendarType={initCalendarType}
      isHideLink={isHideLink}
      isHideFullscreen={isHideFullscreen}
      isShowDateEndInit={isShowDateEndInit}
    />
  );
});

export default GanttWithLoad;