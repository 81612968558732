import { useState } from "react";
import { cloneDeep, isEmpty } from 'lodash';

export const numericWorks = (works, prefix = '', depth = 0) => {
  if (isEmpty(works)) {
    return depth;
  }

  depth++;
  let rowNum = 0;
  let subDepth = depth;
  works.filter(work => !work.isRemove).forEach(work => {
    if (work.isBaseGroup && !work.isFromOtherProject) {
      work.rowNum = '0';
      depth = Math.max(depth, numericWorks(work.subRows, prefix, subDepth - 1));
    } else {
      work.rowNum = `${prefix}${++rowNum}`;
      depth = Math.max(depth, numericWorks(work.subRows, work.rowNum + '.', subDepth));
    }
  });

  return depth;
}

export const useGanttHistory = (initState) => {
  initState.maxDepth = numericWorks(initState.work);
  const [dataLocal, setDataLocal] = useState<{
    work: any,
    link: any;
    maxDepth: number
  }[]>([initState]);

  const [index, setIndex] = useState(0);
  const decIndex = () => setIndex(oldIndex => oldIndex === 0 ? oldIndex : oldIndex - 1);
  const incIndex = () => setIndex(oldIndex => oldIndex >= dataLocal.length - 1 ? oldIndex : oldIndex + 1);

  const reload = (state) => {
    state.maxDepth = numericWorks(state.work);
    setDataLocal([state]);
    setIndex(0);
  };

  const updateDataAll = (updateFunc) => {
    setDataLocal(oldDataLocal => {
      const oldData = cloneDeep(oldDataLocal[index]);
      const newData = updateFunc(oldData);
      newData.maxDepth = numericWorks(newData.work);
      const result = [
        ...oldDataLocal.slice(0, index + 1),
        newData,
      ];

      setIndex(() => result.length - 1);
      return result;
    });
  };

  const updateData = (newDataFunc, newLinkFunc) => {
    updateDataAll((oldData) => ({
      work: newDataFunc(oldData.work),
      link: newLinkFunc(oldData.link)
    }));
  };

  return {
    undo: decIndex,
    rendo: incIndex,
    isHasChanges: index > 0,
    isUndoDisabled: index === 0,
    isRendoDisabled: index >= dataLocal.length - 1,
    data: dataLocal[index],
    updateData,
    updateDataAll,
    reload
  };
};