import React, { useRef } from 'react';
import { useAppSelector } from '@/store';
import services from "@/services";
import { useCheckRight } from "@/utils/hooks";
import { getDictObj } from '@/utils';
import { getBasicNewProjectData } from "@/actions/getBasicNewProjectData";
import { useDispatch } from "react-redux";
import AgreementComment from "@/pages/CreateProject/Agreement/agreementComment";
import { ProjectStatus, UserRight } from '@/config/const';
import { RollbackIcon } from '@/pages/CreateProject/BasicNav/Icon';
import i18n from 'i18next';

const rollbackSend = (projectId: number, data: IRollbackData) => services.post(`/project/agree/rollback/${projectId}`, data);

interface IRollbackData {
  comment: string;
}

const SendToRollback = () => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const checkRight = useCheckRight();

  const [projectVersionId, projectId, statusId, nextStatusCode, isProjectClosed, statusDict] = useAppSelector(state => [
    state.NewProject.newProjectData.id,
    state.NewProject.newProjectData.projectId,
    state.NewProject.newProjectData.statusId,
    state.NewProject.newProjectData.projectNextStatusCode,
    state.NewProject.newProjectData.isClosed,
    state.dict.status,
  ]);

  const isProjectActive = getDictObj(statusDict, statusId)?.code === ProjectStatus.RELEASE;
  const isNextAgree = ProjectStatus.COORDINATION === nextStatusCode;
  const isShowButton = isProjectActive && !isProjectClosed && !isNextAgree && checkRight(UserRight.PROJECT_ROLLBACK);

  const open = () => {
    modalRef.current.handleClose(true);
  }

  const send = async (comment) => {
    await rollbackSend(projectId, { comment });

    dispatch(getBasicNewProjectData(projectVersionId));
  }

  const onClick = () => {
    open();
  }

  return (
    <>
      {isShowButton && (
        <button className='rollback-button' onClick={onClick} title={i18n.t('Go to rollback')}>
          <RollbackIcon className="rollback-icon"/>
        </button>
      )}
      <AgreementComment ref={modalRef} onOk={send} rollback/>
    </>
  );
}

export default SendToRollback;