import React from 'react';
import { Modal } from 'react-bootstrap';

import TextArea from '@/elements/TextArea';
import ButtonRt from "@/components/Helper/ButtonRt";
import cx from "classnames";
import DocBlock from '@/components/Doc';
import { isEmpty } from 'lodash';
import Checkbox from '@/elements/Checkbox';

class AgreementComment extends React.Component<any, any> {
  docRef: any;

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      acceptCheck: !props.rollback,
    }

    this.docRef = React.createRef();
  }

  handleClose = (isShow) => {
    this.setState({
      show: isShow,
      comment: '',
    })
  }

  ok = async () => {
    const { onOk } = this.props;

    if (onOk) {
      const docParams = this.props.docParams;

      if (docParams?.otherId) {
        await this.docRef.current?.deleteFiles(docParams.projectVersionId, docParams.otherId);
        await this.docRef.current?.sendFiles(docParams.projectVersionId, docParams.otherId);
      }

      const result = await onOk(this.state.comment?.trim());
      const loadId = result?.result;

      if(loadId && !docParams?.otherId) {
        await this.docRef.current?.deleteFiles(docParams.projectVersionId, loadId);
        await this.docRef.current?.sendFiles(docParams.projectVersionId, loadId);
      }

      if(result?.after) {
        result.after();
      }
    }

    this.handleClose(false)
  }

  handleChangeComment = (e) => {
    this.setState({
      comment: e.target.value,
    })
  }

  getTitle = () => {
    if (this.props.title) {
      return this.props.title;
    }

    if (this.props.reject) {
      return 'Возврат на доработку';
    }

    if (this.props.rollback) {
      return 'Отменить данную версию согласованной карточки';
    }

    if (this.props.accept) {
      return 'Согласовать с комментарием';
    }

    return null;
  }

  getNeedCommentText = () => {
    return `Необходимо ввести комментарий ${this.props.reject ? 'отклонения' : ''}`.trim()
  }

  getOkText = () => {
    if (this.props.reject) {
      return 'Отправить';
    }

    if (this.props.rollback) {
      return 'Подтвердить';
    }

    if (this.props.accept) {
      return 'Согласовать';
    }

    return null;
  }

  render() {
    const { comment } = this.state;
    const letterCountInComment = comment?.trim().match(/[a-zа-я0-9]/gi)?.length;
    const docParams = this.props.docParams;
    const isCommitteeAgree = this.props.committee;

    return (
      <React.Fragment>
        <Modal show={this.state.show}
               onHide={() => this.handleClose(false)}
               animation={false}
               backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>{this.getTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.props.commentNotRequired && this.getNeedCommentText()}
            <TextArea
              required={!this.props.commentNotRequired}
              label="Комментарий"
              value={comment}
              onChange={this.handleChangeComment}
              description={this.props.commentNotRequired ? undefined : 'Требуется ввести не менее 3 символов'}
            />
            {docParams && (
              <DocBlock
                isEdit={true}
                hideTitle
                typeCode={docParams.typeCode}
                ref={this.docRef}
                projectId={docParams.projectVersionId}
                otherId={docParams.otherId ?? 0}
                loadByProject
                isRejectRemove
                ignoreOwnerFiles
                componentType={isCommitteeAgree ? 'button' : undefined}
                defaultTypeCode={isCommitteeAgree ? 'COORDINATION_AGREE_USER' : undefined}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.props.rollback && (
              <Checkbox
                checked={this.state.acceptCheck}
                onChange={() => this.setState({ acceptCheck: !this.state.acceptCheck })}
                className='rollback_warning_message'
              >
                Я понимаю, что нажатие на кнопку «Подтвердить» приведет к тому, что статус «Согласовано» получит предыдущая согласованная версия этой карточки.
                Текущая согласованная версия станет версией «На доработке», а в текущей версии «На редактировании» изменения будут потеряны.
              </Checkbox>
            )}
            <div className='button-felix-group'>
              <ButtonRt
                className={cx({ 'outline': this.props.rollback, 'danger': this.props.rollback })}
                onClick={this.ok}
                disabled={!this.props.commentNotRequired
                  && ((!comment?.trim() || !letterCountInComment || letterCountInComment < 3)
                  || !this.state.acceptCheck)}
              >
                {this.getOkText()}
              </ButtonRt>
              <ButtonRt type="outline" onClick={() => this.handleClose(false)}>
                Отмена
              </ButtonRt>
            </div>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AgreementComment;
