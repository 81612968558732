import { ICommonModalOptions } from '@/pages/General/CommonModal';

export interface IModalState {
  message: any;
  options: ICommonModalOptions;
  loadCount: number;
}

export const initialState = {
  message: '',
  options: {},
  loadCount: 0
};

export const Modal = (state: IModalState = initialState, action) => {
  switch (action.type) {
    case 'MODAL_SHOW':
      return {
        ...state,
        message: action.payload.message,
        options: action.payload.options || {},
      };
    case 'MODAL_HIDE':
      return { ...state, message: '', options: {} };
    case 'LOAD_INC':
      return { ...state, loadCount: state.loadCount + 1 };
    case 'LOAD_DEC':
      return { ...state, loadCount: state.loadCount - 1 };
    default:
      return state;
  }
};
