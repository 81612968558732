import React, { useEffect, useState } from 'react';
import { fillWorksStatus } from '@/pages/CreateProject/Blocks/Gantt';
import { checkGroup } from '@/pages/CreateProject/Blocks/utils';
import WorkGroupItem from '@/pages/Dashboard2/DashboardProjectStatus/WorkList/WorkGroupItem';
import { useAppSelector } from '@/store';
import { workDataLoader } from '@/pages/CreateProject/Blocks/WorkList';

const getGroups = (works: Work[]) => works
  .filter((work) => checkGroup(work) && work.isShowDashboard)
  .map(work => ({
    ...work,
    children: works.filter((childWork) => !checkGroup(childWork) && childWork.workGroupId === work.id)
  }));

const Message = ({ text }) => (
  <div className="text-muted">
    {text}
  </div>
);

const WorkList = ({ currentProject }) => {
  const [workStatusDict] = useAppSelector(state => [state.dict.workStatus]);
  const [works, setWorks] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const groups = getGroups(works);
  const { projectVerId, typeCode } = currentProject;

  useEffect(() => {
    if (!workStatusDict || !workStatusDict.isLoad) {
      return;
    }

    if (!projectVerId) {
      setWorks([]);
      return;
    }

    setIsLoad(true);

    workDataLoader(projectVerId).then(data => {
      setIsLoad(false);
      setWorks(fillWorksStatus(data, workStatusDict));
    });
  }, [projectVerId, workStatusDict]);

  if (isLoad) {
    return <Message text="Загрузка..." />;
  }

  if (!projectVerId) {
    return <Message text="Выберите проект" />;
  }

  if (!groups.length) {
    return <Message text="Нет данных" />;
  }

  return (
    <div className="dashboard-work-group__container">
      {groups.map(group => (
        <WorkGroupItem key={group.id} group={group} projectType={typeCode} />
      ))}
    </div>
  );
};

export default WorkList;
