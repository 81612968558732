import { Link } from 'react-router-dom';

import { ProgressBar } from 'react-bootstrap'
import cx from 'classnames';

import { formatDays } from '@/utils';
import { getPathByType } from '@/utils/project';
import { calcSkupProcentNum } from '@/pages/CreateProject/Blocks/Components/SkypForm'
import {
  getStatusText, 
  getWorkSuccessInfo,
  getDelayOfDay,
  getWorkDelayColorIndicator
} from '@/pages/Dashboard2/DashboardProjectStatus/WorkList/utils'
import Table from '@/components/Table';
import React from 'react';

const PercentWithBar = ({ cntSuccess, cnt }) => {
  const percent = calcSkupProcentNum({ cntSuccess, cnt });

  return (
    <div>
      <div className="dashboard-work-table-percent">
        {cntSuccess} из {cnt} ({percent}%)
      </div>
      <ProgressBar className="mini" now={percent} />
    </div>
  )
}

const getDetailColumns = (projectType) => ([
  {
    dataField: 'name',
    text: 'Работы, входящие в группу',
    formatter: (name, { projectVersionId, id }) => (
      <Link target="_blank" to={`/${getPathByType(projectType)}/${projectVersionId}/work/${id}`}>
        {name}
      </Link>
    )
  },
  {
    dataField: 'skup.regionName',
    text: 'Субъект РФ',
  },
  {
    dataField: 'status.name',
    text: 'Статус',
    formatter: (_, row) => getStatusText(row)
  },
  {
    dataField: 'percent',
    text: 'Процент завершения',
    formatter: (_, row) => {
      const workCount = getWorkSuccessInfo(row);

      return (
        <PercentWithBar cnt={workCount.cnt} cntSuccess={workCount.cntSuccess} />
      )
    }
  },
  {
    dataField: 'delayOfDay',
    text: 'Дней просрочки',
    formatter: (_, row) => {
      const daysDelay = getDelayOfDay(row);

      if (daysDelay === null) {
        return null;
      }

      const colorIndicator = getWorkDelayColorIndicator(row);
      return (
        <div className="flex-center-vertical">
          <div className={cx('color-indicator with-text-right work-indicator', colorIndicator)}></div>
          <div className="color-indicator-text">{formatDays(daysDelay)}</div>
        </div>
      )
    }
  },
  {
    dataField: 'comment',
    text: 'Комментарий к статусу работ',
  },
])

const WorkTable = ({ works, projectType }) => {
  const detailColumns = getDetailColumns(projectType);

  return (
    <Table
      keyField='id'
      data={works}
      columns={detailColumns}
      noDataIndication="Данные не найдены"
      big
    />
  );
}

export default WorkTable;
