import cx from 'classnames';
import GanttJS from '@/components/FrappeGantt/src';
import { isEmpty, isEqual, round } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FORMAT_DATE_SERVER } from '@/config/const';
import { useDeviceContext } from '@/context/DeviceContext';
import { getWorkProcent } from '@/pages/CreateProject/Blocks/Components/ProgressForm';
import { calcSkupProcentNum } from '@/pages/CreateProject/Blocks/Components/SkypForm';
import {
	checkGroup,
	checkMilestone,
	checkTask,
	getWorkNameWithProject,
	isWorkExpired,
} from '@/pages/CreateProject/Blocks/utils';
import {
	formatDate,
	getDictCodeById,
	getDictObj,
	getVersionHistoryName,
	isEmptyValues,
	parseDate,
} from '@/utils';
import { useAppSelector } from '@/store';
import {
	barCreator, BarPosition,
	dateEndInitBarCreator,
	dateEndInitPopupCreator,
	milestoneBarCreator, popupCreator
} from "@/pages/CreateProject/Blocks/ganttUtils";
import { IProjectHistoryState } from "@/reducers/ProjectHistory";
import { flatten, groupWorks } from '@/components/Gantt/util/utils';

export const fillWorksStatus = (works: Work[], statusDict: DictItem): IGanttWork[] => {
	return works.map(work => ({
		...work,
		status: getDictObj(statusDict, work.statusId)
	}));
};

const prepareForGantt = (works: Work[], links: GanttTableLink[], dict: any, projectId: number, statusDict: DictItem): IGanttWork[] => {
	return flatten(groupWorks(fillWorksStatus(works, statusDict))).map(work => ({
		...work,
		name: projectId === work.projectId ? work.name : getWorkNameWithProject(work, dict.types, works),
		dependencies: links.filter(l => l.toId === work.id).map(l => ({ ...l, type: getDictCodeById(dict.workLinkType, l.typeId) })),
	} as IGanttWork));
};

export const calcGroupPercent = (data: Work[], item) => {
	const groupId = item.id;
	const childrenInfo = data
		.filter(({ workGroupId }) => workGroupId === groupId)
		.filter((work) => !checkGroup(work))
		.filter(({ status }) => status?.code !== 'CANCEL')
		.map(({ weight, skup, status }) => {
			return {
				weight: isEmptyValues(weight) ? 1 : weight / 100,
				percent: skup
					? calcSkupProcentNum(skup)
					: (['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(status?.code) ? 100 : 0)
			};
		});

	// тут немного наркомании, описанно в задаче https://rit-phab.rt.ru/T3419
	const weightSum = childrenInfo.reduce((acc, { weight }) => acc + weight, 0);
	const weightSumPercent = childrenInfo.reduce(
		(acc, { weight, percent }) => acc + ((weightSum === 0 ? 1 : weight) * percent), 0);

	return weightSumPercent / (weightSum === 0 ? 1 : weightSum);
};

const nameWithProgress = (item, skupPercent, workPercent) => {
	let result = '';
	if (item.skup || checkGroup(item)) {
		result = ` (прогресс: ${skupPercent})`;
	} else if (item.plannedValue != null) {
		result = ` (прогресс: ${workPercent}%)`;
	}

	return item.name + result;
};

const progressValue = (item, skupPercent, workPercent) => {
	return skupPercent || workPercent || 0;
};

export const getProgress = (data, item: Work | GanttTableItem) => {
	if (checkMilestone(item)) {
		return 100;
	}

	const skupPercent = round(checkGroup(item) ? calcGroupPercent(data, item) : calcSkupProcentNum(item.skup));
	const workPercent = getWorkProcent(item.factValue, item.plannedValue);

	const progress = progressValue(item, skupPercent, workPercent);
	if (progress > 100) {
		return 100;
	}

	return progress;
};

interface ICreateTaskProps {
	rowId: number;
	item: IGanttWork;
	works: IGanttWork[];
	showName?: boolean;
	className?: string;
	arrowClassName?: string;
	labelClassName?: string;
	taskIdSuffix?: string;
	position?: BarPosition;
	popupNameAdd?: string;
}

const createTask = ({
	rowId,
	item,
	works,
	showName = true,
	className = 'bar',
	arrowClassName = 'arrow',
	labelClassName = '',
	taskIdSuffix = '',
	position = 'CENTER',
	popupNameAdd = '',
} : ICreateTaskProps) => {
	const isMilestone = checkMilestone(item);
	const isGroup = checkGroup(item);
	const skupPercent = round(isGroup ? calcGroupPercent(works, item) : calcSkupProcentNum(item.skup));
	const workPercent = getWorkProcent(item.factValue, item.plannedValue);
	const dateEnd = isMilestone ? item.dateStart : (item.status?.code === 'SUCCESS' ? item.dateEndFact : item.dateEnd);
	const name = nameWithProgress(item, skupPercent, workPercent);
	const popupName = `${name}${popupNameAdd ? ` (${popupNameAdd})` : ''}`;

	return {
		row_id: rowId,
		start: formatDate(item.dateStart, FORMAT_DATE_SERVER),
		end: formatDate(dateEnd, FORMAT_DATE_SERVER),
		name: showName ? name : '',
		popupName: popupName,
		rawData: item,
		id: `${item.id}${taskIdSuffix}`,
		progress: getProgress(works, item),
		dependencies: item.dependencies.map(dep => `${dep.fromId}${taskIdSuffix}`).join(','),
		custom_class: [
			'STATUS_DICT',
			item.status?.code,
			isWorkExpired(item) ? 'EXPIRED' : '',
			isGroup ? 'GROUP' : '',
			isMilestone ? 'MILESTONE' : '',
		].join(' '),
		createBar: isMilestone
			? milestoneBarCreator({ className, position })
			: barCreator({ className, labelClassName, position }),
		createBarProgress: isMilestone
			? milestoneBarCreator({ className: `${className}-progress`, position })
			: barCreator({ className: `${className}-progress`, labelClassName, position, isProgress: true }),
		arrowClass: arrowClassName,
		custom_popup_html: null,
	};
}

const createDateEndInit = (
	rowId: number,
	item: IGanttWork,
	position: BarPosition = 'CENTER',
) => {
	const isTask = checkTask(item);
	const parsedDateEnd = parseDate(item.dateEnd);
	const parsedDateEndInitial = parseDate(item.dateEndInitial);
	if (isTask && parsedDateEndInitial && (!parsedDateEnd || parsedDateEnd.diff(parsedDateEndInitial, 'days'))) {
		return {
			row_id: rowId,
			start: formatDate(item.dateEndInitial, FORMAT_DATE_SERVER),
			end: formatDate(item.dateEndInitial, FORMAT_DATE_SERVER),
			name: '',
			popupName: '',
			rawData: item,
			id: `${item.id}-initial`,
			progress: 100,
			dependencies: '',
			custom_class: '',
			createBar: dateEndInitBarCreator({className: 'bar-end-init', position}),
			createBarProgress: dateEndInitBarCreator({className: 'bar-end-init-progress', position}),
			arrowClass: 'arrow',
			custom_popup_html: dateEndInitPopupCreator(),
		};
	}
	return null;
}

interface IGanttProps {
	projectId: number;
	works: Work[];
	secondaryWorks?: Work[];
	links?: GanttTableLink[];
	secondaryLinks?: GanttTableLink[];
	projectBase?: string;
	isShowDateEndInit?: boolean;
	scrollContainerClassName?: string;
}

interface IGanttWork extends GanttTableItem {
	isExtractedForMerge?: boolean;
	dependencies?: GanttTableLink[];
}

const Gantt = ({
	projectId,
	works,
	secondaryWorks,
	links = [],
	secondaryLinks = [],
	projectBase,
	isShowDateEndInit = false,
	scrollContainerClassName = undefined
}: IGanttProps) => {
	const { isMobile } = useDeviceContext();
	const [isFullScreen, setIsFullScreen] = useState(false);
	const statusDict = useAppSelector(state => state.dict.workStatus);
	const dict = useAppSelector(state => state.dict);
	const ref = useRef(null);
	const [ganttInst, setGanttInst] = useState(undefined);
	const [data, setData] = useState<IGanttWork[]>([]);
	const [secondaryData, setSecondaryData] = useState<IGanttWork[]>([]);
	const projectHistory = useAppSelector(state => state.ProjectHistory[projectId] || ({} as IProjectHistoryState));

	const parseData = (data: IGanttWork[], secondaryData: IGanttWork[]) => {
		//Определяем какие данные должны быть в первой строчке (предыдущая версия должна быть сверху (было), следующая снизу (стало))
		const isFirstVersionInTop = !projectHistory.isEnabled
			|| projectHistory.firstVersionId <= projectHistory.secondVersionId;
		const firstVersionName = getVersionHistoryName(projectHistory.versions
			?.find(item => item.projectVersionId === (projectHistory.firstVersionId)));
		const secondVersionName = getVersionHistoryName(projectHistory.versions
			?.find(item => item.projectVersionId === (projectHistory.secondVersionId)));

		let allTask = [];
		let index = 0;

		allTask = allTask.concat(data.flatMap((item) => {
			const tasks = [];

			//Блок работ для сравнения
			if (projectHistory.isEnabled && item.projectId === projectId) {
				const secondaryItem = secondaryData.find(sw => (sw.workId || sw.id) === (item.workId || item.id));
				if (secondaryItem) {
					secondaryItem.isExtractedForMerge = true;
					if (isFirstVersionInTop) {
						tasks.push(createTask({
							rowId: index,
							item: secondaryItem,
							works: secondaryData,
							position: 'BOTTOM',
							popupNameAdd: secondVersionName,
						}));
					} else {
						tasks.push(createTask({
							rowId: index,
							item: secondaryItem,
							works: secondaryData,
							className: 'bar-secondary',
							arrowClassName: 'arrow-secondary',
							labelClassName: 'secondary',
							taskIdSuffix: '-secondary',
							position: 'TOP',
							popupNameAdd: secondVersionName,
						}));
					}

					//Базовая дата окончания
					if (isShowDateEndInit) {
						const dateEndInit = createDateEndInit(index, secondaryItem, isFirstVersionInTop ? 'BOTTOM' : 'TOP');
						if (dateEndInit) {
							tasks.push(dateEndInit);
						}
					}
				}
			}

			//Основной блок работ
			if (projectHistory.isEnabled && item.projectId === projectId) {
				if (isFirstVersionInTop) {
					tasks.push(createTask({
						rowId: index,
						item,
						works: data,
						className: 'bar-secondary',
						arrowClassName: 'arrow-secondary',
						labelClassName: 'secondary',
						taskIdSuffix: '-secondary',
						position: 'TOP',
						popupNameAdd: firstVersionName,
					}));
				} else {
					tasks.push(createTask({
						rowId: index,
						item,
						works: data,
						position: 'BOTTOM',
						popupNameAdd: firstVersionName,
					}));
				}
			} else {
				tasks.push(createTask({
					rowId: index,
					item,
					works: data,
				}));
			}

			//Базовая дата окончания
			if (isShowDateEndInit) {
				const dateEndInit = createDateEndInit(index, item, projectHistory.isEnabled ? (isFirstVersionInTop ? 'TOP' : 'BOTTOM') : 'CENTER');
				if (dateEndInit) {
					tasks.push(dateEndInit);
				}
			}

			index++;
			return tasks;
		}));

		//Оставшиеся блоки из второй версии
		allTask = allTask.concat(secondaryData.filter(item => item.projectId === projectId && !item.isExtractedForMerge).flatMap(item => {
			const tasks = [];

			if (projectHistory.isEnabled) {
				if (isFirstVersionInTop) {
					tasks.push(createTask({
						rowId: index,
						item,
						works: secondaryData,
						position: 'BOTTOM',
						popupNameAdd: secondVersionName,
					}));
				} else {
					tasks.push(createTask({
						rowId: index,
						item,
						works: secondaryData,
						className: 'bar-secondary',
						arrowClassName: 'arrow-secondary',
						labelClassName: 'secondary',
						taskIdSuffix: '-secondary',
						position: 'TOP',
						popupNameAdd: secondVersionName,
					}));
				}
			} else {
				tasks.push(createTask({
					rowId: index,
					item,
					works: secondaryData,
				}));
			}

			if (isShowDateEndInit) {
				const dateEndInit = createDateEndInit(index, item, projectHistory.isEnabled ? (isFirstVersionInTop ? 'BOTTOM' : 'TOP') : 'CENTER');
				if (dateEndInit) {
					tasks.push(dateEndInit);
				}
			}

			index++;
			return tasks;
		}))

		return allTask;
	};

	useEffect(() => {
		if (isFullScreen && !isMobile) {
			setIsFullScreen(false);
		}
	}, [isMobile]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!statusDict || !statusDict.isLoad) {
			return;
		}

		if (works) {
			const newData = prepareForGantt(works, links, dict, projectId, dict.workStatus);
			if (!isEqual(data, newData)) {
				setData(newData);
			}
		}

		if (secondaryWorks) {
			const newSecondaryData = prepareForGantt(secondaryWorks, secondaryLinks, dict, projectId, dict.workStatus);
			if (!isEqual(secondaryData, newSecondaryData)) {
				setSecondaryData(newSecondaryData);
			}
		}
	}, [projectId, works, secondaryWorks, links, secondaryLinks, dict]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!ref) {
			return;
		}

		if (isEmpty(data) && isEmpty(secondaryData)) {
			if (ganttInst) {
				ganttInst.clear();
			}
			return;
		}

		if (ganttInst) {
			ganttInst.refresh(parseData(data, secondaryData));
			return;
		}

		setGanttInst(new GanttJS(ref.current, parseData(data, secondaryData), {
			language: 'ru',
			readonly: true,
			view_mode: 'Month',
			custom_popup_html: popupCreator({ statusDict, projectBase })
		}));
	}, [ref, data, secondaryData, isShowDateEndInit, projectHistory]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleClick = () => {
		setIsFullScreen(!isFullScreen);
	};

	const fullScreenButtonContent = isFullScreen ? (
		<svg className="gantt_button__icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M13.0601 11.9992L19.0012 6.05813L17.9406 4.99747L11.9995 10.9386L6.05841 4.99746L4.99775 6.05813L10.9388 11.9992L4.99777 17.9403L6.05843 19.001L11.9995 13.0599L17.9405 19.001L19.0012 17.9403L13.0601 11.9992Z" fill="#101828" />
		</svg>
	) : (
		<>
			<span className="gantt_button__text">На весь экран</span>
			<svg className="gantt_button__icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z" fill="#101828" fillOpacity="0.05" />
				<path fillRule="evenodd" clipRule="evenodd" d="M22 10.5H25C25.2761 10.5 25.5 10.7239 25.5 11V14H27V11C27 9.89543 26.1046 9 25 9H22V10.5ZM14 10.5V9H11C9.89543 9 9 9.89543 9 11V14H10.5V11C10.5 10.7239 10.7239 10.5 11 10.5H14ZM10.5 22H9V25C9 26.1046 9.89543 27 11 27H14V25.5H11C10.7239 25.5 10.5 25.2761 10.5 25V22ZM22 25.5V27H25C26.1046 27 27 26.1046 27 25V22H25.5V25C25.5 25.2761 25.2761 25.5 25 25.5H22Z" fill="#101828" />
			</svg>
		</>
	);

	return (
		<div className={cx('gantt_container', { 'gantt_container__fullScreen': isFullScreen })}>
			{isMobile && (
				<div className="gantt_button" onClick={handleClick}>
					{fullScreenButtonContent}
				</div>
			)}
			<ScrollContainer className={scrollContainerClassName} hideScrollbars={false}>
				<div ref={ref}></div>
			</ScrollContainer>
		</div>
	);
};

export default Gantt;
