import service from '@/services';
import services from "@/services";

export const getBasicNewProjectData = (id, cb = undefined) => {
  return async (dispatch) => {
    // должно выполнятся последовательно, ибо файлы смотрят на статус проекта!
    const projectData = await service.get(`/project/${id}`);
    projectData.files = await service.get(`/file/project/${id}`);
    projectData.stageInfo = await services.get(`/project/agree/${id}/stageInfo`);
    const projectId = projectData?.projectId;
    if (projectId) {
      projectData.agreeFiles = await service.get(`/file/agreeUser/${projectId}`);
    }

    dispatch({
      type: 'SET_NEW_PROJECT_DATA',
      payload: projectData,
    });

    if (cb) {
      cb(projectData);
    }

    return projectData
  };
};
